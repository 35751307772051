// This pack is primarily here to trigger rendering of all other packs we've appended. But you can also
// use it for something that should be on every page.

// Like our global Highcharts config!
import 'chartkick/highcharts';
import Highcharts from 'highcharts';
import Rails from '@rails/ujs';
import { toHumanNumber } from '../highchart_options';
import { chartFontFamily } from '../constants';
Rails.start();

Highcharts.setOptions({
  colors: ['#0E9C1B'],
  chart: {
    backgroundColor: 'none',
    style: {
      'font-family': chartFontFamily.join(', ')
    }
  },
  xAxis: {
    type: 'datetime',
    tickLength: 0,
    crosshair: true,
    lineColor: '#E5E8E7',
    labels: {
      distance: 8,
      style: {
        fontSize: '10px',
        color: '#99A89B'
      }
    }
  },
  yAxis: {
    tickAmount: 3,
    min: 0,
    labels: {
      style: {
        fontSize: '10px',
        color: '#99A89B',
        whiteSpace: 'nowrap'
      }
    }
  },
  tooltip: {
    backgroundColor: '#FAFBFA',
    borderColor: '#E9ECE9',
    borderRadius: 4,
    borderWidth: 1,
    outside: true,
    stickOnContact: true,
    padding: 5,
    shadow: {
      color: '#005E09',
      opacity: 0.02,
      offsetX: 0,
      offsetY: 0,
      width: 8
    },
    formatter: function() {
      return '<p class="px-1 text-grey-700 font-sans font-medium text-sm">' + this.x + ': £' + toHumanNumber(this.y) + '</p>';
    }
  },
  // Removes highcharts.com credit link
  credits: { enabled: false }
});

window.Highcharts = Highcharts;

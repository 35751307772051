// Import all our icon SVGs
import ContractsIcon from '../assets/images/contracts.svg';
import DatesIcon from '../assets/images/dates.svg';
import SectorsIcon from '../assets/images/sectors.svg';
import BuyersIcon from '../assets/images/buyers.svg';
import ValueIcon from '../assets/images/value.svg';
import SuppliersIcon from '../assets/images/suppliers.svg';

// If you are adding a new option to the contractStatusOptions filter
// see comments in lib/filter/checkbox.rb above #assign_nil_to_contract_status_other_filter
const opportunityStatusOptions = [
  ['Open', 'Open tender'],
  ['Closed', 'Closed tender'],
  ['Pre-Information', 'Future opportunity'],
  ['Other', 'Other']
];

// TODO: TPP - Remove after TPP is enabled
const contractStatusOptions = [
  ['Open', 'Open'],
  ['Closed', 'Closed'],
  ['Pre-Information', 'Pre Information'],
  ['Other', 'Other']
];

const contractSMEOptions = [
  ['Y', 'Yes'],
  ['N', 'No'],
  ['All', 'Include all']
];

const contractFrameworkOptions = [
  ['Y', 'Yes'],
  ['N', 'No'],
  ['All', 'Include all']
];

const suitableSMEOptions = [
  ['true', 'Yes'],
  ['false', 'No'],
  ['All', 'Include all']
];

const supplierSMEOptions = [
  ['Y', 'Yes'],
  ['N', 'No'],
  ['All', 'Include all']
];

const supplierNameAndOrCategoryOptions = [
  ['and', 'Filter by name and category'],
  ['or', 'Filter by name or category']
];

const contractTypeOptions = [
  ['Works', 'Works'],
  ['Services', 'Services'],
  ['Products', 'Products'],
  ['Supplies', 'Supplies'],
  ['None', 'Not specified']
];

const keyDecisionMakerSeniorityOptions = [
  ['Chairman', 'Chairman'],
  ['Vice/Deputy Chairman', 'Vice / Deputy Chairman'],
  ['Chief Officer', 'Chief Officer'],
  ['Deputy/Assistant Chief Officer', 'Deputy / Assistant Chief Officer'],
  ['Clerk/Secretary', 'Clerk / Secretary'],
  ['Top Level Director', 'Top Level Director'],
  ['Second Level / Head of Service', 'Second Level / Head of Service'],
  ['Third Level/Head of Service/Manager', 'Third Level / Head of Service / Manager'],
  ['Unspecified', 'Unspecified']
];

const isCalloffOptions = [
  ['Y', 'Yes'],
  ['N', 'No'],
  ['All', 'Include all']
];

// TODO: Live/Expired Date
// KEEP after removing feature flag, this is used in constants below: allRelativeDateOptionsWithLiveDateOptions, allRelativeDateOptionsWithLiveDateOptionsAndPrevFinancialYear
// Remove comment after removing feature flag
const liveDateOptions = [
  ['live', 'Live'],
  ['live_and_unknown', 'Live and Unknown'],
  ['expired', 'Expired']
];

const invoiceBuyerTypes = [
  {
    label: '',
    options: [
      ['Central Government', 'Central Government'],
      ['Local Government', 'Local Government'],
      ['NHS', 'NHS'],
      ['Rail', 'Rail'],
      ['Devolved Transport', 'Devolved Transport'],
      ['Policing', 'Policing'],
      ['Fire Service', 'Fire Service'],
      ['Charitable Organisation', 'Charitable Organisation'],
      ['Private Limited', 'Private Limited']
    ]
  }
];

const invoiceSupplierTypes = [
  ['private', 'Private'],
  ['public', 'Public'],
  ['All', 'All']
];

const pastDates = {
  label: 'Past',
  options: [
    ['prev_24_months', 'Last 24 months'],
    ['prev_12_months', 'Last 12 months'],
    ['prev_6_months', 'Last 6 months'],
    ['prev_3_months', 'Last 3 months'],
    ['prev_30_days', 'Last 30 days'],
    ['prev_14_days', 'Last 14 days'],
    ['prev_7_days', 'Last 7 days'],
    ['prev_calendar_year', 'Previous calendar year'],
    ['year_to_date', 'Year to date'],
    ['month_to_date', 'Month to date']
  ]
};

// TODO: Financial Year
// KEEP after removing feature flag, this is used in constants below: justPastDatesWithPrevFinancialYear, allRelativeDateOptionsWithPrevFinancialYear, pastRelativeDateOptionsWithPrevFinancialYear
// Remove comment after removing feature flag
const pastDatesWithPrevFinancialYear = {
  label: 'Past',
  options: [
    ['prev_24_months', 'Last 24 months'],
    ['prev_12_months', 'Last 12 months'],
    ['prev_6_months', 'Last 6 months'],
    ['prev_3_months', 'Last 3 months'],
    ['prev_30_days', 'Last 30 days'],
    ['prev_14_days', 'Last 14 days'],
    ['prev_7_days', 'Last 7 days'],
    ['prev_financial_year', 'Previous financial year'],
    ['prev_calendar_year', 'Previous calendar year'],
    ['year_to_date', 'Year to date'],
    ['month_to_date', 'Month to date']
  ]
};

const futureDates = {
  label: 'Future',
  options: [
    ['next_7_days', 'Next 7 days'],
    ['next_14_days', 'Next 14 days'],
    ['next_30_days', 'Next 30 days'],
    ['next_3_months', 'Next 3 months'],
    ['next_6_months', 'Next 6 months'],
    ['next_12_months', 'Next 12 months'],
    ['next_24_months', 'Next 24 months']
  ]
};

const presentDates = {
  label: 'Present',
  options: [
    ['yesterday', 'Yesterday'],
    ['today', 'Today'],
    ['tomorrow', 'Tomorrow']
  ]
};

const justPastDates = [
  pastDates
];

// TODO: Financial Year
// KEEP after removing feature flag, this is used in app/javascript/invoice_filter_sidebar/index.jsx
// Remove comment after removing feature flag
const justPastDatesWithPrevFinancialYear = [
  pastDatesWithPrevFinancialYear
];

const pastRelativeDateOptions = [
  pastDates,
  {
    label: 'Present',
    options: [
      ['yesterday', 'Yesterday'], ['today', 'Today']
    ]
  }
];

const allRelativeDateOptions = [
  pastDates,
  presentDates,
  futureDates
];

// TODO: Financial Year
// KEEP after removing feature flag, this is used in:
// app/javascript/contract_award_filter_sidebar/index.jsx, app/javascript/contract_notice_filter_sidebar/index.jsx, app/javascript/framework_filter_sidebar/index.jsx
// Remove comment after removing feature flag
const allRelativeDateOptionsWithPrevFinancialYear = [
  pastDatesWithPrevFinancialYear,
  presentDates,
  futureDates
];

// TODO: Financial Year
// KEEP after removing feature flag, this is used in:
// app/javascript/contract_award_filter_sidebar/index.jsx, app/javascript/framework_filter_sidebar/index.jsx
// Remove comment after removing feature flag
const pastRelativeDateOptionsWithPrevFinancialYear = [
  pastDatesWithPrevFinancialYear,
  {
    label: 'Present',
    options: [
      ['yesterday', 'Yesterday'], ['today', 'Today']
    ]
  }
];

// TODO: Live/Expired Date
// KEEP after removing feature flag, this is used in:
// app/javascript/contract_award_filter_sidebar/index.jsx
// Remove comment after removing feature flag
const allRelativeDateOptionsWithLiveDateOptions = [
  {
    label: 'Status',
    options: liveDateOptions
  },
  ...allRelativeDateOptions
];

// TODO: Live/Expired Date
// KEEP after removing feature flag, this is used in:
// app/javascript/contract_award_filter_sidebar/index.jsx, app/javascript/framework_filter_sidebar/index.jsx
// Remove comment after removing feature flag
const allRelativeDateOptionsWithLiveDateOptionsAndPrevFinancialYear = [
  {
    label: 'Status',
    options: liveDateOptions
  },
  ...allRelativeDateOptionsWithPrevFinancialYear
];

const buyerTypeOptions = [
  {
    label: 'Public Sector',
    options: [
      ['Central Government', 'Central Government'],
      ['Local Government', 'Local Government'],
      ['NHS', 'NHS'],
      ['Rail', 'Rail'],
      ['Devolved Transport', 'Devolved Transport'],
      ['Policing', 'Policing'],
      ['Fire Service', 'Fire Service'],
      ['Further and Higher Education', 'Further and Higher Education'],
      ['Schools', 'Schools'],
      ['Parish Councils', 'Parish Councils']
    ]
  },
  {
    label: 'Private Sector',
    options: [
      ['Limited Liability Partnership', 'Limited Liability Partnership'],
      ['Private Limited', 'Private Limited'],
      ['Private Limited w/o Share Capital', 'Private Limited w/o Share Capital'],
      ['Private Unlimited', 'Private Unlimited'],
      ['Public Limited Company', 'Public Limited Company'],
      ['Non Limited', 'Non Limited'],
      ['Foreign Company', 'Foreign Company'],
      ['Joint Venture', 'Joint Venture'],
      ['Unknown', 'Unknown']
    ]
  },
  {
    label: 'Non Profit',
    options: [
      ['Housing Association', 'Housing Association'],
      ['Charitable Organisation', 'Charitable Organisation'],
      ['Non Profit Organisation', 'Non Profit Organisation'],
      ['The Church of England', 'The Church of England']
    ]
  }
];

const employeeCountOptions = [
  ['1 - 10', '1 - 10'],
  ['11 - 50', '11 - 50'],
  ['51 - 250', '51 - 250'],
  ['251 - 500', '251 - 500'],
  ['501 - 1000', '501 - 1000'],
  ['1001 - 5000', '1001 - 5000'],
  ['5001 - 10,000', '5001 - 10,000'],
  ['10,000+', '10,000+'],
  ['Unknown', 'Unknown']
];

// Export our icons in an object
const icons = {
  contracts: ContractsIcon,
  dates: DatesIcon,
  sectors: SectorsIcon,
  buyers: BuyersIcon,
  value: ValueIcon,
  suppliers: SuppliersIcon
};

const chartFontFamily = [
  'Untitled Sans',
  'Inter',
  'Roboto',
  'Helvetica Neue',
  'Arial Nova',
  'Nimbus Sans',
  'Arial',
  'sans-serif'
];

export {
  contractStatusOptions, // TODO: TPP - Remove after TPP is enabled
  contractSMEOptions,
  contractFrameworkOptions,
  contractTypeOptions,
  allRelativeDateOptions,
  allRelativeDateOptionsWithPrevFinancialYear,
  allRelativeDateOptionsWithLiveDateOptions,
  allRelativeDateOptionsWithLiveDateOptionsAndPrevFinancialYear,
  justPastDates,
  justPastDatesWithPrevFinancialYear,
  opportunityStatusOptions,
  pastRelativeDateOptions,
  pastRelativeDateOptionsWithPrevFinancialYear,
  suitableSMEOptions,
  supplierSMEOptions,
  supplierNameAndOrCategoryOptions,
  isCalloffOptions,
  buyerTypeOptions,
  employeeCountOptions,
  invoiceBuyerTypes,
  invoiceSupplierTypes,
  keyDecisionMakerSeniorityOptions,
  icons,
  chartFontFamily
};
